import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder  } from "@angular/forms";
import { Category } from '../../models/Category';
import { MoneyApiService } from '../../services/money-api.service';
import { ITransaction } from '../../models/ITransaction';
import { ClaimsService } from '../../services/claims-service.service';
import { TransactionSaveModel } from '../../models/TransactionSaveModel';

@Component({
  selector: 'app-transaction-entry',
  templateUrl: './transaction-entry.component.html',
  styleUrls: ['./transaction-entry.component.css']
})
export class TransactionEntryComponent implements OnInit {

  public transactionForm;

  @Input() 
  get transaction() : ITransaction { return this._transaction; }
  set transaction(thisTran : ITransaction) {
    this._transaction = thisTran;
    this.addTransactionType = thisTran.amount > 0 ? "D" : "P";
    this.resetFormFieldsToCurrentTransaction();
  }

  // The transaction being matched
  private _transaction: ITransaction;

  // Properties for "Add Transaction"
  addTransactionType : string = 'P';
  
  isAddingTransfer() {
    return this.addTransactionType == "T";
  }

  isAddingPayment() {
    return this.addTransactionType == "P";
  }
  
  categoryList : Array<Category>;

  private splitIndexList : Array<number> = [1];
  private instanceId : number;
  
  @Output() onAddNewTransaction = new EventEmitter();
  @Output() onCancelNewTransaction = new EventEmitter();

  constructor(private moneyService : MoneyApiService, private claimsService : ClaimsService, private formBuilder: FormBuilder) { 
    this.instanceId = claimsService.getInstanceId();

    this.transactionForm = this.formBuilder.group({
      TransDt: ["", { validators: [ Validators.required ] }],
      Description: ["", { validators: [Validators.required] }],
      TranType: ["P"],
      Memo: [""],
      CheckNumber: ["", { validators: [ Validators.pattern("\\d*") ] }],
      Splits: this.formBuilder.array([       
        this.createNewSplit(0)
      ])
    });
  }

  //Form Getters
  get TransDt() {
    return this.transactionForm.controls.TransDt;
  }

  get Description() {
    return this.transactionForm.controls.Description;
  }
    
  get TranType() {
    return this.transactionForm.controls.TranType;
  }

  get Memo() {
    return this.transactionForm.controls.Memo;
  }

  get CheckNumber() {
    return this.transactionForm.controls.CheckNumber;
  }

  showNotValid(field) {
    return field.invalid && (field.dirty || field.touched);
  }

  hasError(field, errorType) {
    return field.errors?.[errorType];
  }

  ngOnInit() {    

    this.moneyService.getCategoryList(this.instanceId).subscribe((data: Category[]) => {
      this.categoryList = data;
    });        
    
  }

  resetFormFieldsToCurrentTransaction() {
    this.transactionForm.reset();
    const splitCount : number = this.transactionForm.controls.Splits.length;
    for (let i=1; i < splitCount; i++) {  // for splitCount is 1, this will skip
      this.transactionForm.controls.Splits.removeAt(1); //already remove the second item, the right number of times
    }

    if (this._transaction != null) {
      this.transactionForm.setValue({
        TransDt: this._transaction.transDt,
        Description: this._transaction.description,
        TranType: this.addTransactionType,
        Memo: this._transaction.memo,
        CheckNumber: this._transaction.checkNum?.toString() || "",
        Splits: [
          { 
            CategoryId: "",
            Amount: ""
          }
        ]
      });
    }
  }

  showMoreSplits() {
    const splits = this.transactionForm.controls.Splits;
    splits.push(this.createNewSplit(null));
  }
  
  createNewSplit(amount:number): FormGroup {
    return this.formBuilder.group({
      CategoryId: ["", { validators: [ Validators.required ] }],
      Amount: ["", { validators: [ Validators.required, Validators.pattern(/^\-?\d*\.?\d{1,2}$/) ] }] 
    });
  }

  addTransaction() {
    let transaction : TransactionSaveModel = new TransactionSaveModel();

    const form = this.transactionForm.controls;

    transaction.accountId = this._transaction.accountId;
    transaction.transDt = new Date(form.TransDt.value);
    transaction.description = form.Description.value;
    transaction.memo = form.Memo.value;
    transaction.checkNum = parseInt(form.CheckNumber.value);
    
    let negativeTransactionMultiplier : number = 1;
    if (this.isAddingPayment()) {
      negativeTransactionMultiplier = -1;
    }

    const splits = form.Splits; //the formarray
    let totalAmount : number = 0;

    for (let i = 0; i < splits.length; i++) {
      let categoryId : number = parseInt(splits.at(i).controls.CategoryId.value);
      let amount : number = negativeTransactionMultiplier * parseFloat(splits.at(i).controls.Amount.value);
      totalAmount += amount;
      transaction.splits[categoryId] = amount;
    }

    transaction.amount = totalAmount;

    this.moneyService.addTransaction(transaction).subscribe(data => {
      transaction.transId = data.transId;
      this.onAddNewTransaction.emit(transaction);
      this.resetFormFieldsToCurrentTransaction();
    });
  }

  reset() {
    this.transaction = null;    
    this.addTransactionType = 'P';
  }

  cancelTransaction() {
    this.resetFormFieldsToCurrentTransaction();
    this.onCancelNewTransaction.emit();
  }

  //P = Payment, D = Deposit, T = Transfer
  changeTransactionType(e) {
    //TODO: remove logging
    console.log(this.transactionForm.controls);
    console.log(this.transactionForm.valid);
    this.addTransactionType = e.value;
  }

}
