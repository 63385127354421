import { Component } from '@angular/core';
import { AuthService } from './services/AuthService';
import { ActivatedRoute } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private authService : AuthService, private activeRoute: ActivatedRoute, private idle: Idle) {
    this.idle.setIdle(60 * 15); // 15 minutes
    this.idle.setTimeout(5); //seconds

    // sets the default interrupts. see https://github.com/grbsk/ng2-idle/blob/1439dd16ee30508be673d9b5b1f253a1b02e7df9/projects/core/src/lib/defaultinterruptsources.ts#L17
    // maybe customize this to not do mousemove. 
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);  
    this.idle.watch();

    //Handle Idle events
    this.idle.onIdleStart.subscribe(() => {
      console.log("You've gone idle");
    });

    this.idle.onIdleEnd.subscribe(() => {
      console.log("No longer idle");
    });

    this.idle.onTimeout.subscribe(() => {
      console.log("Timed out!");
      this.authService.logout();
    })
  }

  ngOnInit() {
    let codeExists : boolean = false;

    if (document.location.href.indexOf("code=") >= 0 && document.location.href.indexOf("scope=") >= 0)
      codeExists = true;

    if (this.authService.hasValidAccessToken() == false && codeExists) {
      this.authService.checkForAuthCodeResponse();
    }
  }//end ngOnInit
}//end class
